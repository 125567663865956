<template>
  <div>
    <system-index v-if="isShowMenu('menu:system_settings')" />
    <tenant-index v-else />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import SystemIndex from '@/views/items/system/index'
import TenantIndex from '@/views/items/tenant/index'
export default {
  name: 'ItemList',
  components: {
    SystemIndex,
    TenantIndex
  },
  methods: {
    isShowMenu(menu) {
      return hasPermission(menu)
    }
  }
}
</script>
