<template>
  <div>
    <a-form class="custom-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="8" :xxl="6">
          <a-form-item label="项目名称">
            <a-input
              v-decorator="['item_name', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入项目名称"
              allow-clear
              autocomplete="off"
            />
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item label="类型">
            <a-select
              v-decorator="['item_type']"
              placeholder="请选择类型"
              allow-clear
            >
              <a-select-option v-for="item in itemTypes" :key="item.name" :value="item.slug">
                {{ item.name }}
              </a-select-option>

            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item label="状态">
            <a-select
              v-decorator="['effective', { initialValue: 'true' }]"
              placeholder="请选择状态"
              allow-clear
            >
              <a-select-option key="true" value="true">
                生效
              </a-select-option>
              <a-select-option key="false" value="false">
                失效
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="8" :xxl="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { getItemTypeOptions } from '@/api/item'

export default {
  name: 'SearchItem',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'item_search' }),
      itemTypes: []
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    getOptions() {
      getItemTypeOptions().then(res => {
        this.itemTypes = res.data
      })
    },

    clearForm() {
      this.form.setFieldsValue({ effective: undefined })
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

