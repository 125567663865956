<template>
  <div>
    <search @submit="submitSearch" ref="searchForm" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="showSortModal">排序</a-button>
        <a-button type="primary" @click="showNewModal">新增</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="fetchData"
    >
      <template slot="images" slot-scope="imgs, record">
        <!--        <span v-if="imgs && imgs.length > 0" class="custom-blue" @click="showImg(record)">{{ imgs[0].response.filename }}</span>-->
        <img v-if="imgs && imgs.length > 0" :src="imgs[0].url" style="height: 50px;width: 50px" @click="showImg(record)">
      </template>
      <div slot="effective" slot-scope="effective, record">
        <a-switch
          :checked="effective"
          :loading="editingEffectiveId === record.id"
          @change="(checked) => changeEffective(checked, record)"
        />
      </div>
      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a @click="showEditModal(record)">编辑</a>
          <a @click="deleteItem(record.id)" class="custom-delete">删除</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 新增项目模态框 -->
    <new-item
      v-if="isShowNewModal"
      :visible.sync="isShowNewModal"
      @completed="fetchData"
    />

    <!-- 编辑项目模态框 -->
    <edit-item
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :record="editingRecord"
      @completed="fetchData"
    />
    <!-- 预览图片 -->
    <preview-image
      :visible.sync="previewVisible"
      :images.sync="previewImages"
    />
    <sort
      v-if="isShowSortModal"
      :visible.sync="isShowSortModal"
      :data="sortData"
      table-name="items"
      name-title="项目名称"
      @completed="fetchSortData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import Search from '@/views/items/Search'
import PreviewImage from '@/components/PreviewImage'
import { formatCurrency } from '@/utils/filter'
import { deleteItem, findItems, updateItemEffective, findSortItems } from '@/api/item'

export default {
  name: 'ItemList',
  components: {
    Pagination,
    PreviewImage,
    Search,
    Sort: () => import('@/components/Sort'),
    NewItem: () => import('@/views/items/New'),
    EditItem: () => import('@/views/items/Edit')
  },
  data() {
    return {
      query: { effective: 'true' },
      data: [],
      loading: true,
      isShowNewModal: false, // 是否显示新增表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowMaintainModal: false, // 是否显示维护家属告知书表单弹窗
      editingEffectiveId: 0, // 正在修改有效性的记录id
      editingRecord: {}, // 正在编辑的记录id
      previewVisible: false,
      previewImages: [],
      isShowSortModal: false,
      sortData: [],
      pagination: {
        total_count: 0
      }
    }
  },
  created() {
    this.fetchData()
    this.$EventBus.$on('showImg', val => {
      this.previewVisible = true
      this.previewImages = val.images
    })
  },
  destroyed() {
    this.$EventBus.$off('showImg')
  },
  computed: {
    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name',
          fixed: 'left',
          width: 150
        },
        {
          title: '项目代码',
          dataIndex: 'code',
          width: 150
        },
        {
          title: '拼音码',
          dataIndex: 'pinyin',
          width: 150
        },
        {
          title: '类型',
          width: 90,
          dataIndex: 'item_type'
        },
        {
          title: '关联套餐个数',
          dataIndex: 'package_count',
          width: 120
        },
        {
          title: '有效性',
          width: 120,
          dataIndex: 'effective',
          scopedSlots: { customRender: 'effective' }
        },
        {
          title: '计价单位',
          width: 90,
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          width: 90,
          dataIndex: 'count'
        },
        {
          title: '价格(元)',
          width: 120,
          dataIndex: 'price',
          customRender: formatCurrency
        },
        {
          title: '图片',
          width: 100,
          dataIndex: 'images',
          scopedSlots: { customRender: 'images' }
        },
        {
          title: '项目介绍',
          width: 150,
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          title: '操作',
          width: 150,
          fixed: 'right',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    showImg(record) {
      this.previewVisible = true
      this.previewImages = record.images
    },

    showNewModal() {
      this.isShowNewModal = true
    },

    showEditModal(record) {
      this.editingRecord = record
      this.isShowEditModal = true
    },

    showSortModal() {
      findSortItems().then((res) => {
        this.sortData = res.data
        this.isShowSortModal = true
      })
    },

    showMaintainModal(record) {
      this.editingRecord = record
      this.isShowMaintainModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchSortData() {
      this.query = {}
      this.$refs.searchForm.clearForm()
      this.loading = true
      findItems().then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      findItems(this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    changeEffective(checked, record) {
      const vm = this
      const effective = checked

      this.$confirm({
        title: effective ? '确定将项目生效吗?' : '确定失效项目吗?',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingEffectiveId = record.id
          updateItemEffective(record.id, { effective: effective })
            .then((res) => {
              vm.editingEffectiveId = 0
              if (res.code === 0) {
                record.effective = checked
              }
            })
            .catch(() => {
              vm.editingEffectiveId = 0
            })
        }
      })
    },

    deleteItem(recordId) {
      const thisForm = this
      this.$confirm({
        title: '确定进行删除吗？删除后无法恢复',
        content: '',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          deleteItem(recordId).then(() => {
            thisForm.fetchData()
          })
        }
      })
    }
  }
}
</script>
